"use client";
import { logoutUser } from "@/server/actions/user/auth/logout";
// import { OAuthExtension } from "@magic-ext/oauth";
import { Magic as MagicBase, SupportedLocale } from "magic-sdk";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Web3 from "web3";

export type Magic = MagicBase;

type MagicContextType = {
  magic: Magic | null;
  web3: Web3 | null;
  switchNetwork: (rpcUrl: string, chainId: number) => Promise<void>;
  currentChainId: number | null;
};

const MagicContext = createContext<MagicContextType>({
  magic: null,
  web3: null,
  switchNetwork: async () => {},
  currentChainId: null,
});

export const useMagic = () => useContext(MagicContext);

const MagicProvider = ({ children }: { children: ReactNode }) => {
  const [magic, setMagic] = useState<Magic | null>(null);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [currentChainId, setCurrentChainId] = useState<number | null>(null);

  useEffect(() => {
    initializeMagic();
  }, []);

  // Detect the user's preferred language
  const getUserLocale = () => navigator.language || "en";

  // Called initially and for switching networks
  const initializeMagic = async (
    rpcUrl: string = `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
    chainId: number = 1
  ) => {
    try {
      // Added error handling
      const userLocale = getUserLocale() as SupportedLocale;
      const magic = new MagicBase(
        process.env.NEXT_PUBLIC_MAGIC_API_KEY as string,
        {
          locale: userLocale,
          network: { rpcUrl, chainId },
          // extensions: [new OAuthExtension()],
        }
      );
      // @ts-ignore
      setMagic(magic);
      setWeb3(new Web3((magic as any).rpcProvider));
      setCurrentChainId(chainId);
      if (!magic.user.isLoggedIn()) {
        await logoutUser(); // clear basic info cookie if user is not logged in
      }
    } catch (error) {
      console.error("Failed to initialize Magic:", error);
    }
  };

  const switchNetwork = async (rpcUrl: string, chainId: number) => {
    await initializeMagic(rpcUrl, chainId);
  };

  // Return values available globally through context
  const value = useMemo(() => {
    return {
      magic,
      web3,
      switchNetwork,
      currentChainId,
    };
  }, [magic, web3, currentChainId]); // Included currentChainId in dependencies

  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};

export default MagicProvider;
