"use client";

import Alert from "@/components/ui/Alert";
import { HeartIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import IconHeartSolid from "../../../../../../components/ui/Icons/IconHeartSolid";
import { useAccount } from "wagmi";
import { useMagic } from "@/context/MagicProvider";

type Props = {
  liked: boolean;
  likesCount: number;
};

export default function BtnHeart({ liked, likesCount }: Props) {
  const [likedOptimistic, setLikedOptimistic] = useState(liked);
  const [optimisticLikesCount, setOptimisticLikesCount] = useState(likesCount);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Like updated!");

  const { address, isConnected } = useAccount();
  const { magic } = useMagic();

  const handleClick = async () => {
    // Save previous state in case we need to revert
    const prevLiked = likedOptimistic;
    const prevLikesCount = optimisticLikesCount;

    // Optimistic update first
    const newLiked = !likedOptimistic;
    setLikedOptimistic(newLiked);
    setOptimisticLikesCount(
      newLiked ? optimisticLikesCount + 1 : optimisticLikesCount - 1
    );

    // If user is connected already, no further checks
    if (address && isConnected) {
      return;
    }

    // Check login with Magic if not connected
    const isLoggedIn = await magic?.user.isLoggedIn();

    if (!isLoggedIn) {
      // Revert the optimistic updates
      setLikedOptimistic(prevLiked);
      setOptimisticLikesCount(prevLikesCount);

      // Show login alert
      setSnackbarMessage("Please log in to like this Collection.");
      setSnackbar(true);
    }
  };

  return (
    <div className="flex items-center">
      <button
        type="submit"
        className="ml-4 flex items-center justify-center rounded-md px-3 py-3"
        onClick={handleClick}
      >
        {likedOptimistic ? (
          <>
            <IconHeartSolid />
            <span className="sr-only">Unlike this NFT</span>
          </>
        ) : (
          <>
            <HeartIcon className="h-6 w-6 hover:text-dark-pri" />
            <span className="sr-only">Like this NFT</span>
          </>
        )}
      </button>
      {optimisticLikesCount > 0 && (
        <span className="text-sm">{optimisticLikesCount}</span>
      )}
      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
